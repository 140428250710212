import * as React from 'react';
import '@fontsource/comfortaa/300.css'; // Weight 300.
import '@fontsource/comfortaa'; // Defaults to weight 400.
import '@fontsource/comfortaa/500.css'; // Weight 500.
import '@fontsource/comfortaa/600.css'; // Weight 600.
import '@fontsource/comfortaa/700.css'; // Weight 700.
import 'bootstrap/dist/css/bootstrap.min.css';
import Layout from '../components/layout';
import Logo from '../images/logo/logo.jpg';
import { Link } from 'gatsby';
import styled from 'styled-components';
import Container from '@material-ui/core/Container';
import StarIcon from '@material-ui/icons/Star';
import Grid from '@material-ui/core/Grid';
import Card from '@material-ui/core/Card';
import Typography from '@material-ui/core/Typography';
import CssBaseline from '@material-ui/core/CssBaseline';
import Icon1 from '../assets/activityplanner.jpg';
import Icon2 from '../assets/ai.jpg';
import Icon3 from '../assets/goals.jpg';
import FacebookIcon from '@material-ui/icons/Facebook';
import Icon4 from '../assets/groups.jpg';
import Icon5 from '../assets/expert.jpg';
import Icon6 from '../assets/mood.jpg';
import Screenshot1 from '../assets/1.png';
import Screenshot2 from '../assets/2.png';
import Screenshot3 from '../assets/3.png';
import Qr from '../assets/qr.svg';
import LinkedInIcon from '@material-ui/icons/LinkedIn';

const IndexStyled = styled.div`
	font-size: 20px;

	.feature {
		padding: 2em;
	}

	.featureTitle {
		margin: 1em;
		font-size: 1.2em;
		font-weight: 600;
	}

	.card {
		background-color: white;
		margin: 10px;
		box-shadow: 3px 2px 10px 3px #7f7e7e5c !important;
		border-radius: 10px !important;
		padding: 1em;
		min-height: 16em;
	}

	.background {
		background: rgb(122, 116, 228);
		background: linear-gradient(
			108deg,
			rgba(122, 116, 228, 1) 39%,
			rgba(27, 138, 249, 1) 79%
		);
		height: 75vh;
	}

	.second {
		background: #e2f2fa;
	}

	.highlight {
		background: #7a74e4;
		height: 30vh;
		align-items: center;
		justify-content: center;
		display: flex;
		color: #fff;
		font-size: 1.3em;
		padding: 0 5em;
		font-family: 'Comfortaa';
	}

	.tryapp {
		background: #7a74e4;
		align-items: center;
		justify-content: center;
		color: #fff;
		text-align: center;
		padding: 0em 5em;
		font-family: 'Comfortaa';
		margin: 0;
	}

	.tryapp p {
		font-size: 1em;
	}

	.tryapp img {
		margin: 1em;
	}

	.app {
		background: #e6e6ff;
		align-items: center;
		justify-content: center;
		color: #000;
		font-size: 1.3em;
		padding: 5em 5em;
		font-family: 'Comfortaa';
		text-align: center;
	}

	.heroText {
		align-items: center;
		justify-content: center;
		display: flex;
		height: 60vh;
		color: #fff;
		font-size: 2.5em;
		padding: 1em 3em;
		font-family: 'Comfortaa';
	}

	.secondText {
		align-items: center;
		justify-content: center;
		/* height: 40vh; */
		color: #000;
		margin-top: 5em;
		font-size: 1.2em;
		padding: 1em 1em;
		font-family: 'Comfortaa';
	}

	.heroImage {
		align-items: center;
		justify-content: center;
		display: flex;
		height: 60vh;
		color: #fff;
		font-size: 2.5em;
		padding: 1em 0.5em;
		font-family: 'Comfortaa';
	}

	.vision {
		margin-bottom: 3em;
	}

	.reviews {
		margin-bottom: 3em;
	}

	.card p {
		margin: 1em 0.5em;
		text-align: center;
		font-size: 0.9em;
	}

	.app-image img {
		width: 100%;
		margin-top: 1em;
	}
	// mobile settings
	@media (max-width: 768px) {
		h2 {
			font-weight: 600;
			font-size: 1.3em;
			line-height: 1.6em;
		}
		.background {
			height: 100%;
			/* padding-top */
		}

		.secondText {
			margin-top: 0em;
		}

		.heroImage {
			padding: 0;
		}
		.heroText {
			font-size: 1.6em;
			text-align: center;
			padding: 0em;
			margin-bottom: -7em;
		}

		.highlight {
			height: 100%;
			padding: 3em 1em;
		}
		.app {
			height: 100%;
			padding: 3em 1em;
		}
	}
`;
// styles
const pageStyles = {
	color: '#232129',
	webkitFontSmoothing: 'antialiased',
	fontFamily: 'Comfortaa',
	marginTop: '3em',
};

// markup
const IndexPage = () => {
	return (
		<IndexStyled>
			<Layout>
				<div>
					<CssBaseline />
					<Typography component='div' className='background'>
						<Grid container>
							<Grid item xs={12} sm={7}>
								<div className='heroText'>
									<div>
										Your journey in growing your life towards greater
										satisfaction starts here...
									</div>
								</div>
							</Grid>
							<Grid item xs={12} sm={5}>
								<div className='heroImage'>
									<iframe
										title='Walking guy'
										src='https://embed.lottiefiles.com/animation/72763'
										loading='lazy'
										style={{
											height: '600px',
											width: '900px',
											marginTop: '2em',
										}}
									></iframe>
								</div>
							</Grid>
						</Grid>
					</Typography>
				</div>
				<div>
					<CssBaseline />
					<Typography component='div' className='second'>
						<Grid container>
							<Grid item xs={12} sm={6}>
								<div className='heroImage'>
									{/* <iframe
										title='Hero Image'
										src='https://embed.lottiefiles.com/animation/44505'
										style={{ height: '359px', width: '400px' }}
										loading='lazy'
									></iframe> */}
									<iframe
										width='560'
										height='315'
										src='https://www.youtube.com/embed/RLNjMmPY82w'
										title='YouTube video player'
										frameborder='0'
										allow='accelerometer; autoplay; clipboard-write; encrypted-media; gyroscope; picture-in-picture'
										allowfullscreen
									></iframe>
								</div>
							</Grid>
							<Grid item xs={12} sm={6}>
								<div className='secondText'>
									<h2>Why Grow My Life?</h2>
									<div>
										Our vision is a world where everyone can achieve growth and
										be guided and assisted in their journey.
										<p>
											Our aim for Grow My Life is to reach a wide audience and
											enrich lives. We are building a trustworthy platform with
											expert content that supports personal and professional
											growth. Come and grow with us and share your growth ideas
											on the platform! - Dr. Paul Cooper, Founder
										</p>
									</div>
								</div>
							</Grid>
						</Grid>
					</Typography>
				</div>
				<main style={pageStyles}>
					<Container maxWidth='md'>
						<title>Home Page</title>
						<center>
							<h2>Features</h2>
							<p>
								Focus on your personal goals in a fun and easy way. Grow My Life
								is a fresh new way to look after your personal growth and is
								unlike anything you’ve ever used before
							</p>
						</center>
					</Container>
					<Container>
						<center>
							<Grid container spacing={3}>
								<Grid item xs={12} sm={4}>
									<div className='feature'>
										<img src={Icon1} alt='Activity Planner' width='120' />
										<h3 class='featureTitle'>Activity Planner</h3>
										<div>
											Create your personalised activity plan to focus on your
											goals
										</div>
									</div>
								</Grid>
								<Grid item xs={12} sm={4}>
									<div className='feature'>
										<img src={Icon2} alt='AI Suggestions' width='120' />
										<h3 class='featureTitle'>AI Suggestions </h3>
										<div>
											Get tailored activity suggestions suited to your profile
											and goals
										</div>
									</div>
								</Grid>
								<Grid item xs={12} sm={4}>
									<div className='feature'>
										<img src={Icon3} alt='Goals Tracker' width='120' />
										<h3 class='featureTitle'>Goals Tracker</h3>
										<div>
											Keep a track of your goals by checking in to your
											activities daily
										</div>
									</div>
								</Grid>
								<Grid item xs={12} sm={4}>
									<div className='feature'>
										<img src={Icon4} alt='Growth Groups' width='120' />
										<h3 class='featureTitle'>Growth Groups</h3>
										<div>
											Connect with friends and family to work towards shared
											goals{' '}
										</div>
									</div>
								</Grid>
								<Grid item xs={12} sm={4}>
									<div className='feature'>
										<img src={Icon5} alt='Expert recommendations' width='120' />
										<h3 class='featureTitle'>Expert recommendations</h3>
										<div>
											Browse through carefully curated activities reviewed by
											experts
										</div>
									</div>
								</Grid>
								<Grid item xs={12} sm={4}>
									<div className='feature'>
										<img src={Icon6} alt='Mood Tracker' width='120' />
										<h3 class='featureTitle'>Mood Tracker</h3>
										<div>
											Record how you feel while doing your activities on a daily
											basis
										</div>
									</div>
								</Grid>
							</Grid>
						</center>
					</Container>
					{/*
					<Container maxWidth='md' className='vision'>
						 <Grid container spacing={5}>
							<Grid item xs={12} sm={6}>
								<h3> Our vision</h3>
								<div className=''>
									Our aim for Grow My Life is to reach a wide audience and
									enrich lives. We are building a trustworthy platform with
									expert content that supports personal and professional growth.
									Come and grow with us and share your growth ideas on the
									platform! - Dr. Paul Cooper, Founder
								</div>
							</Grid>
							<Grid item xs={12} sm={6}>
								<iframe
									title='Hero Image'
									src='https://embed.lottiefiles.com/animation/44505'
									style={{ height: '359px', width: '400px' }}
									loading='lazy'
								></iframe>
					
							</Grid>
						</Grid> 
					</Container>
						*/}
					<Typography component='div' className='app'>
						<Container maxWidth='md'>
							<h2>
								Achieve personal growth, gain inspiration from great ideas and
								expert guidance
							</h2>
							<Grid container spacing={3}>
								<Grid item xs={12} sm={4}>
									<div className='app-image'>
										<img src={Screenshot1} alt='Grow My Life - Screenshot' />
									</div>
								</Grid>
								<Grid item xs={12} sm={4}>
									<div className='app-image'>
										<img src={Screenshot2} alt='Grow My Life - Screenshot' />
									</div>
								</Grid>
								<Grid item xs={12} sm={4}>
									<div className='app-image'>
										<img src={Screenshot3} alt='Grow My Life - Screenshot' />
									</div>
								</Grid>
							</Grid>
						</Container>
					</Typography>
					<Typography component='div' className='highlight'>
						<center>
							We’d like to invite you to join our early adopters’ program, and
							help us to shape the platform and activities to best suit you and
							your friend’s personal growth
						</center>
					</Typography>
					{/* <Container maxWidth='md' className='reviews'>
						<center>
							<h2>What our growers say</h2>
							<p>Here's how Grow My Life has helped our growth community</p>
						</center>
						<Grid container spacing={3}>
							<Grid item xs={12} sm={4}>
								<Card className='card'>
									<center>
										<StarIcon></StarIcon> <StarIcon></StarIcon>
										<StarIcon></StarIcon>
										<StarIcon></StarIcon> <StarIcon></StarIcon>
									</center>
									<p>
										Lorem ipsum dolor sit amet, consectetur adipiscing elit.
										Donec finibus, neque id auctor cursus. - Brandon P
									</p>
								</Card>
							</Grid>
							<Grid item xs={12} sm={4}>
								<Card className='card'>
									<center>
										<StarIcon></StarIcon> <StarIcon></StarIcon>
										<StarIcon></StarIcon>
										<StarIcon></StarIcon> <StarIcon></StarIcon>
									</center>
									<p>
										{' '}
										Lorem ipsum dolor sit amet, consectetur adipiscing elit.
										Donec finibus, neque id auctor cursus. - Brandon P
									</p>
								</Card>
							</Grid>
							<Grid item xs={12} sm={4}>
								<Card className='card'>
									<center>
										<StarIcon></StarIcon> <StarIcon></StarIcon>
										<StarIcon></StarIcon>
										<StarIcon></StarIcon> <StarIcon></StarIcon>
									</center>
									<p>
										{' '}
										Lorem ipsum dolor sit amet, consectetur adipiscing elit.
										Donec finibus, neque id auctor cursus. - Brandon P
									</p>
								</Card>
							</Grid>
						</Grid>
					</Container> */}
					<Typography component='div' className='tryapp'>
						<h2>Try the app to grow your life</h2>
						<p>
							Download Grow My Life on your phone. Simply scan the QR code and
							install
						</p>
						<img src={Qr} alt='Grow My Life - QR' width='200' />

						<p>Follow us for latest updates</p>
						<a href='https://www.facebook.com/growmylifeideas'>
							<FacebookIcon
								color='action'
								style={{ fontSize: '2em' }}
							></FacebookIcon>
						</a>
						<a href='https://www.linkedin.com/company/grow-my-life/'>
							<LinkedInIcon
								color='action'
								style={{ fontSize: '2em' }}
							></LinkedInIcon>
						</a>
					</Typography>
				</main>
			</Layout>
		</IndexStyled>
	);
};

export default IndexPage;
